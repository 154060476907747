import * as React from "react"
import MainLayout from '../../layouts/main'
import {useContext, useEffect, useState} from "react";
import GlobalContext from "../../context/GlobalContext";
import {graphql, useStaticQuery} from "gatsby";
import Breadcrumps from "../../components/Breadcrumbs";
import Partners from "../home/partners";
import ContributiFiscali from "../home/contributi-fiscali";
import Help from "../home/help";
import GalleryButton from "../../components/GalleryButton";
import HeroPage from "../../components/HeroPage";
import SectionInstallazioni from "../../components/SectionInstallazioni";
import SeoComponent from "../../layouts/partials/SeoComponent";

const items = [
    { title: 'Prodotti', href: 'prodotti' },
    { title: 'Rivestimenti' }
]
const installazioni = [
    {
        name: 'Le nostre installazioni',
        gallery: 'tenda-illuminazione-01.jpg',
        list: [
            {
                id: '1',
                src: '/prodotti/tendedasole/Installazione/tenda-illuminazione-01.jpg',
                thumb: '/thumb/tendedasole/Installazione/tenda-illuminazione-01.jpg',
            },
            {
                id: '2',
                src: '/prodotti/tendedasole/Installazione/tenda-illuminazione-02.jpg',
                thumb: '/thumb/tendedasole/Installazione/tenda-illuminazione-02.jpg',
            }
        ]
    },
]

const Rivestimenti = ({pageContext}) => {
    const gContext = useContext(GlobalContext);
    const allImagesQuery = graphql`
            query {
              allFile(
                filter: {
                    relativeDirectory: { regex: "/(dettaglio)/" },
                    extension: {ne: "svg"}
                }
              ) {
                edges {
                  node {
                    base
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]    
                        placeholder: NONE           
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          `
    const {
        allFile: {edges: images},
    } = useStaticQuery(allImagesQuery)
    const [products, setProducts] = useState({});
    useEffect(() => {
        setProducts({
            prodotti: [
                {
                    name: 'Rivestimenti interni',
                    gallery: 'rivestimenti-interni.jpg',
                    items: [
                        {
                            id: '1',
                            src: '/prodotti/rivestimentiInterni/divani_arredamento-moderno_2.jpg',
                            thumb: '/thumb/rivestimentiInterni/divani_arredamento-moderno_2.jpg',
                        },
                        {
                            id: '2',
                            src: '/prodotti/rivestimentiInterni/divano-a-due-posti_divani-pisa_1.jpg',
                            thumb: '/thumb/rivestimentiInterni/divano-a-due-posti_divani-pisa_1.jpg',
                        },
                        {
                            id: '3',
                            src: '/prodotti/rivestimentiInterni/divano-letto_divani_2.jpg',
                            thumb: '/thumb/rivestimentiInterni/divano-letto_divani_2.jpg',
                        },
                        {
                            id: '4',
                            src: '/prodotti/rivestimentiInterni/divano-sinfonia_arredamento-casa_1.jpg',
                            thumb: '/thumb/rivestimentiInterni/divano-sinfonia_arredamento-casa_1.jpg',
                        },
                        {
                            id: '5',
                            src: '/prodotti/rivestimentiInterni/king-simta_arredamenti_2.jpg',
                            thumb: '/thumb/rivestimentiInterni/king-simta_arredamenti_2.jpg',
                        },
                        {
                            id: '6',
                            src: '/prodotti/rivestimentiInterni/orion-simta_arredamenti_2.jpg',
                            thumb: '/thumb/rivestimentiInterni/orion-simta_arredamenti_2.jpg',
                        },
                        {
                            id: '7',
                            src: '/prodotti/rivestimentiInterni/pasha-simta_divani-pisa_2.jpg',
                            thumb: '/thumb/rivestimentiInterni/pasha-simta_divani-pisa_2.jpg',
                        },
                        {
                            id: '8',
                            src: '/prodotti/rivestimentiInterni/prince-simta_divani_1.jpg',
                            thumb: '/thumb/rivestimentiInterni/prince-simta_divani_1.jpg',
                        },
                        {
                            id: '9',
                            src: '/prodotti/rivestimentiInterni/rapsodia-simta_arredamento-moderno_2.jpg',
                            thumb: '/thumb/rivestimentiInterni/rapsodia-simta_arredamento-moderno_2.jpg',
                        },
                        {
                            id: '10',
                            src: '/prodotti/rivestimentiInterni/talismano-simta_arredamento-moderno_2.jpg',
                            thumb: '/thumb/rivestimentiInterni/talismano-simta_arredamento-moderno_2.jpg',
                        },
                        {
                            id: '11',
                            src: '/prodotti/rivestimentiInterni/velluto-africa-simta_arredamento-moderno_2.jpg',
                            thumb: '/thumb/rivestimentiInterni/velluto-africa-simta_arredamento-moderno_2.jpg',
                        },
                        {
                            id: '12',
                            src: '/prodotti/rivestimentiInterni/venere-simta_arredamento-moderno_2.jpg',
                            thumb: '/thumb/rivestimentiInterni/venere-simta_arredamento-moderno_2.jpg',
                        },
                        {
                            id: '13',
                            src: '/prodotti/rivestimentiInterni/zaffiro-simta_arredamenti_2.jpg',
                            thumb: '/thumb/rivestimentiInterni/zaffiro-simta_arredamenti_2.jpg',
                        },
                    ]
                },
            ],
        })
    }, [gContext])
    return (
        <MainLayout pageContext={pageContext}>
            <HeroPage
                image={gContext.getImageFromName(images, 'SIMTA_tessuti_per_rivestimenti.jpg')}
                imageAlt={'SIMTA_tessuti_per_rivestimenti.jpg'}
                overlay={true}
                title={'Rivestimenti'}
            />
            <Breadcrumps  items={items}/>

            <div className="max-w-[1400px] mx-auto py-16 px-4 sm:px-6 lg:px-8 relative w-full">
                <div className="w-full text-justify" dangerouslySetInnerHTML={{ __html: gContext.t('rivestimenti.intro') }}></div>

                <SectionInstallazioni items={installazioni} images={images} classes={'mb-16'}/>

                <h2 className="title-section-light text-center mb-16">{gContext.t('Tutte le tipologie disponibili')}</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-8 sm:gap-y-16 mb-8">
                    {(products?.prodotti || []).map((item) => (
                        <div key={item.name} className="min-h-[300px]">
                            <GalleryButton image={gContext.getImageFromName(images, item.gallery)} imageAlt={`image`} items={item.items} />
                        </div>
                    ))}
                </div>
            </div>


            <ContributiFiscali/>
            <Help/>
            <Partners/>
        </MainLayout>
    )
}

export default Rivestimenti

export const Head = ({pageContext}) => (
    <SeoComponent pageContext={pageContext} />
)